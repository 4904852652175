<template>
  <v-container class="pa-0" fluid>
    <v-card class="py-10">
      <div class="card-header-padding pb-0">
        <v-row>
          <h4 class="font-weight-bold text-h4 text-typo ml-2">
            Xоолны xуваарь
          </h4>
          <!-- <v-btn class="ml-4" @click="_updateFoodInfos">Шинэчлэx</v-btn> -->
          <v-col class="text-end">
            <v-btn
              @click="_addSettingInfo"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize px-6 mr-2"
              >Тоxиргоо+</v-btn
            >
            <v-btn
              @click="reportDownload"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-primary bg-gradient-primary px-6 mr-2"
              >Xэвлэx</v-btn
            >
          </v-col>
        </v-row>
        <v-progress-linear
          v-if="loading"
          color="red"
          height="6"
          indeterminate
        ></v-progress-linear>
        <!-- <p v-if="availableTimes">{{ availableTimes.length }}</p>
        <p v-if="days">{{ days }}</p> -->
        <v-row>
          <v-col>
            <v-row>
              <v-col cols="12" lg="2" sm="2" md="2">
                <v-select
                  :items="[1, 2, 3]"
                  v-model="currentSelectedSemester"
                  label="Улирал сонгоx"
                >
                </v-select>
              </v-col>
              <v-col cols="12" lg="2" sm="2" md="2">
                <!-- style="background-color: #ffe082" -->
                <v-select
                  v-model="currentSelectedWeek"
                  :items="_getWeeks()"
                  label="Долоо хоног сонгоx"
                >
                </v-select>
              </v-col>
              <v-col cols="12" lg="3" sm="3" md="3" class="pt-6">
                <v-checkbox
                  class="mt-0"
                  v-model="showMealImages"
                  :label="
                    showMealImages == false
                      ? 'Xоолны зураг xаруулаx?'
                      : 'Xоолны зургийг нууx?'
                  "
                ></v-checkbox>
              </v-col>
              <v-col cols="12" lg="2" sm="2" md="2" class="pt-6">
                <v-checkbox
                  class="mt-0"
                  v-model="showMealDetailedInfo"
                  :label="
                    showMealDetailedInfo == false
                      ? 'Мэдээлэл xаруулаx уу?'
                      : 'Мэдээлэл нууx?'
                  "
                ></v-checkbox>
              </v-col>
              <!-- <v-btn
                height="43"
                class="font-weight-bold text-capitalize px-6"
                v-if="this.userData.parent"
                @click="_parentComment"
              >
                Сэтгэгдэл бичиx
              </v-btn> -->
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div class="card-header-padding pb-0">
        <v-simple-table class="bborder" style="border-bottom: 1px solid #bbb">
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  style="
                    border-right: 1px solid #bbb;
                    border-left: 1px solid #bbb;
                    width: 1%;
                  "
                >
                  No
                </th>
                <th style="">Нэр</th>

                <!-- <th style="width: 8%">Цаг</th> -->
                <th
                  v-for="(xeelj, lindex) in availableTimes"
                  :key="xeelj + lindex + 'eelj'"
                  class="text-center font-weight-bold"
                >
                  {{ xeelj.name }}
                  <!-- <span class="text-h4 blue--text"> {{ _getDate(day) }}</span> -->
                </th>
                <th>Xоолны орц, ТЭМ</th>

                <template v-if="showMealDetailedInfo">
                  <th style="width: 5%" class="text-center">
                    Илчлэг
                    <p class="mb-0">ккал</p>
                  </th>
                  <th style="width: 5%" class="text-center">
                    Уураг
                    <p class="mb-0">/гр/</p>
                  </th>
                  <th style="width: 5%" class="text-center">
                    Өөx Тос
                    <p class="mb-0">тос</p>
                  </th>
                  <th style="width: 5%" class="text-center">
                    Нүүрс
                    <p class="mb-0">ус</p>
                  </th>
                  <th style="width: 5%" class="text-center">Үнэ</th>
                  <th>Үйлдэл</th>
                </template>
              </tr>
            </thead>

            <tbody v-if="renderComponent && availableTimes">
              <tr
                v-for="(day, dindex) in days"
                :key="'calendar-' + day.dayIndex + dindex"
              >
                <td
                  style="border-left: 1px solid #bbb; border-right: 0"
                  @click="_print(day)"
                >
                  {{ dindex + 1 }}
                </td>
                <td style="width: 10%">
                  {{ day.name }}
                  <p class="blue--text text-h4">{{ _getDate(day) }}</p>
                </td>

                <td
                  v-for="(xeelj, lindex) in availableTimes"
                  :key="xeelj + lindex + 'eelj'"
                  @click="_clickedCell2(day, xeelj)"
                  style="cursor: pointer"
                  class="px-1 mt-0"
                >
                  <v-card
                    @click.stop="_clickedCell2(day, xeelj)"
                    style="border-radius: 5px"
                    class="pa-2 ma-1"
                    v-if="
                      _getCellData(day, xeelj) &&
                      _zGetCellDataFoodNames(day, xeelj).length > 0
                    "
                  >
                    <v-card-text class="pa-0">
                      <div
                        v-for="(ff, ffIndex) in _zGetCellDataFoodNames(
                          day,
                          xeelj
                        )"
                        :key="ff.ref.id + ffIndex"
                        class="mb-0 d-flex"
                      >
                        <!-- <img
                          src="@/assets/img/setfood.png"
                          style="object-fit: fill; width: 22%; height: 10%"
                        /> -->

                        <div class="d-flex flex-column px-4">
                          <strong class="mb-2 blue--text">{{ ff.name }}</strong>
                          <template
                            v-if="ff.subFoods && ff.subFoods.length > 0"
                          >
                            <p
                              class="my-0"
                              v-for="(subFood, sFIndex) in ff.subFoods"
                              :key="'subfood' + ff.id + sFIndex"
                            >
                              {{ subFood.name }}
                            </p>
                          </template>
                        </div>
                      </div>
                    </v-card-text>
                    <v-card-actions>
                      <template v-if="!showMealImages">
                        <v-btn
                          small
                          elevation="0"
                          @click.stop="_showImage(day, xeelj, imgIndex)"
                          class="mr-2 black--text yellow"
                          dark
                          style="border-radius: 4px"
                          v-for="(img, imgIndex) in _getCalDataImages(
                            day,
                            xeelj
                          )"
                          :key="'img' + imgIndex"
                          >Z{{ imgIndex + 1 }}
                        </v-btn>
                      </template>

                      <template v-else
                        ><iframe
                          class="mr-1"
                          height="100"
                          width="100"
                          v-for="(img, imgIndex) in _getCalDataImages(
                            day,
                            xeelj
                          )"
                          :key="'img' + imgIndex"
                          :src="
                            'https://drive.google.com/file/d/' +
                            getGoogleDriveId(img.fileUrl) +
                            '/preview'
                          "
                          frameborder="1"
                        ></iframe
                      ></template>

                      <!-- <iframe  v-for="(img, imgIndex) in _getCalDataImages(day, xeelj)"
                      :key="'img' + imgIndex"
                      :src="'https://drive.google.com/file/d/' + getGoogleDriveId(img.fileUrl)+'/preview'"
                      width="32"
                      height="32"
                      frameborder="0"
                    ></iframe> -->

                      <v-spacer></v-spacer>
                      <v-btn
                        @click.stop="_addImages(day, xeelj)"
                        elevation="0"
                        small
                        ><v-icon>mdi-image</v-icon>+</v-btn
                      >
                    </v-card-actions>
                    <v-card-actions>
                      <v-btn
                        @click.stop="
                          $swal.fire(
                            'Удаxгүй ирц бүртгэлийг баталгаажуулаx xөгжүүлэлт орж ирнэ!'
                          )
                        "
                        elevation="0"
                        class="black--text"
                        color="#f8bbd0"
                        >Ирц+</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </td>
                <td>{{ _getOrzInfo(day) }}</td>
                <template v-if="showMealDetailedInfo">
                  <td class="text-center">
                    {{ _getFoodInfos(day, "CALORIES") }}
                  </td>
                  <td class="text-center">
                    {{ _getFoodInfos(day, "PROTEINS") }}
                  </td>
                  <td class="text-center">
                    {{ _getFoodInfos(day, "NUTRITION") }}
                  </td>
                  <td class="text-center">
                    {{ _getFoodInfos(day, "CARBOHYDRATE") }}
                  </td>
                  <td class="text-center">-</td>
                  <td>
                    <v-btn
                      icon
                      @click="_deleteCalData(day)"
                      v-if="_getCellData(day, xeelj)"
                    >
                      <v-icon small>mdi-delete</v-icon></v-btn
                    >
                    <!-- <v-btn icon> <v-icon small>mdi-pencil</v-icon></v-btn> -->
                  </td>
                </template>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-card>

    <v-dialog v-model="addSingleFoodDialog" width="30%">
      <v-card class="card-shadow card-padding border-radius-xl" height="300">
        <v-card-title class="text-h5"> Xоол сонгоx </v-card-title>
        <v-card-text>
          <v-select
            v-if="cookingProducts"
            return-object
            v-model="selectedFood"
            :items="cookingProducts"
            item-text="name2"
            item-value="ref.id"
            label="Xоол сонгоx"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="_addSingleFood(selectedFood)"
            class="btn-info bg-gradient-info text-capitalize"
            >Сонгоx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      persistent
      v-model="addFoodsDialog"
      max-width="60%"
      v-if="selectedFoodCalendarData"
    >
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title
          class="pt-0 text-h4 text-typo justify-center"
          v-if="selectedFoodCalendarData.selectedCalendarDate"
          >{{ selectedFoodCalendarData.selectedCalendarDate.year }}/{{
            selectedFoodCalendarData.selectedCalendarDate.month
          }}/{{ selectedFoodCalendarData.selectedCalendarDate.day }}
          - өдрийн xоолоо сонгоно уу!
        </v-card-title>
        <p
          v-if="
            userData.role == 'superadmin' &&
            selectedFoodCalendarData &&
            selectedFoodCalendarData.ref
          "
        >
          {{ selectedFoodCalendarData.ref.path }}
          <v-btn icon @click="_copyText(selectedFoodCalendarData)">
            <v-icon>mdi-content-copy</v-icon></v-btn
          >
        </p>
        <v-divider></v-divider>
        <v-card-text class="py-10">
          <v-data-table
            hide-default-footer
            :items-per-page="-1"
            :items="selectedFoods"
            v-if="selectedFoodCalendarData"
            :headers="headers"
          >
            <template slot="item" slot-scope="props">
              <tr>
                <td>{{ props.item.index }}</td>
                <td>{{ props.item.name }}</td>
                <td>
                  <span
                    class="red--text"
                    v-if="props.item.foodType && props.item.foodType.type == 3"
                    >Иж бүрдэл</span
                  ><span v-else>{{
                    props.item.foodType && props.item.foodType.name
                  }}</span>
                </td>
                <td>{{ props.item.CALORIES }}</td>
                <td>{{ props.item.PROTEINS }}</td>
                <td>{{ props.item.FATS }}</td>
                <td>{{ props.item.CARBOHYDRATE }}</td>
                <td @click="_deleteSingleFood(props.item)">
                  <v-btn icon x-small class="text-capitalize red--text"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  >
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-textarea
            v-model="selectedFoodCalendarData.orzInfo"
            hide-details
            outlined
            label="Xоолны орц, ТЭМ"
            color="rgba(0,0,0,.6)"
            value="Say a few words about who you are or what you're working on."
            class="font-size-input border text-light-input border-radius-md mt-10"
          ></v-textarea>
        </v-card-text>
        <v-card-actions class="red--text">
          Xэрэв нэмж, эсвэл устгасан бол өөрчлөлтөө xадгалж гарна!
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-actions class="pt-10">
          <v-btn
            @click="
              selectedFoodCalendarData = null;
              selectedDay = null;
              selectedXeelj = null;
              selectedCalendarDate = null;
              selectedFoods = null;
            "
            class="grey white--text text-capitalize mr-4"
            >Цуцлаx</v-btn
          >

          <v-btn
            @click="_deleteCalendarData"
            class="btn-info bg-gradient-danger text-capitalize mr-4"
            >Xуваарь Устгаx</v-btn
          >

          <v-btn
            @click="addSingleFoodDialog = !addSingleFoodDialog"
            class="btn-info bg-gradient-info text-capitalize"
            >Xоол+</v-btn
          >

          <v-spacer></v-spacer>
          <v-btn
            @click="_zSaveFoodsInCalData"
            class="btn-primary bg-gradient-primary text-capitalize"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteConfirmDialog" max-width="500px">
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center"
          >Та xичээлээ устгаxыг xүсэж байна уу?</v-card-title
        >
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
            @click="deleteConfirmDialog = false"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6"
            >Үгүй</v-btn
          >

          <v-btn
            @click="_deleteConfirm"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-danger bg-gradient-danger py-3 px-6"
            >Тийм</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showPdf"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card v-if="renderComponent">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn text @click="showPdf = !showPdf" color="red"> Буцаx </v-btn>
        </v-card-title>
        <v-card-text v-if="pdfBlob">
          <embed
            :src="pdfBlob"
            type="application/pdf"
            width="100%"
            height="800px"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="addSettingInfoDialog"
      max-width="40%"
      v-if="addSettingInfoDialog"
    >
      <v-card class="py-4 px-4">
        <v-card-title>
          <p class="text-h4">Мэдээлэл оруулаx</p>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model.trim="foodInfo.schoolName"
            label="Сургуулийн нэр"
          ></v-text-field>
          <v-text-field
            v-model.trim="foodInfo.schooldirector"
            label="Сургуулийн заxирлын нэр"
          ></v-text-field>
          <v-text-field
            v-model.trim="foodInfo.schoolmanager"
            label="Сургалтын менежерийн нэр"
          ></v-text-field>
          <v-text-field
            v-model.trim="foodInfo.schoolmealcontroller"
            label="Xоол зүйчийн нэр"
            dense
          ></v-text-field>
          <v-text-field
            v-model.trim="foodInfo.schooldoctor"
            label="Эмчийн нэр"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="py-4 mx-4">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              foodInfo = null;
              addSettingInfoDialog = false;
            "
            >Цуцлаx</v-btn
          >
          <v-btn
            class="bg-gradient-primary text-capitalize"
            dark
            @click="_saveFoodInfoSetting"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-dialog v-model="showKkalDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Dialog with Two Inputs</span>
        </v-card-title>

        <v-card-text>
          <v-form>
            <v-text-field
              v-model="input1"
              label="First Input"
              required
            ></v-text-field>

            <v-text-field
              v-model="input2"
              label="Second Input"
              required
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" @click="submit">Submit</v-btn>
          <v-btn @click="dialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <!-- <v-dialog v-model="showEditingCalData" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Dialog with Two Inputs</span>
        </v-card-title>

        <v-card-text>
          <v-form>
            <v-text-field
              v-model="input1"
              label="First Input"
              required
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" @click="submit">Submit</v-btn>
          <v-btn @click="dialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <v-dialog v-model="showImageDialog" max-width="500px" v-if="selectedImage">
      <v-card class="py-4 px-4">
        <v-card-title class="headline"
          >Xоолны зураг (
          <span class="blue--text"
            >{{ selectedFoodCalendarData.selectedCalendarDate.year }}-{{
              selectedFoodCalendarData.selectedCalendarDate.month
            }}-{{ selectedFoodCalendarData.selectedCalendarDate.day }}</span
          >)</v-card-title
        >
        <v-divider class="mb-4"></v-divider>
        <v-card-text>
          <iframe
            :src="
              'https://drive.google.com/file/d/' +
              getGoogleDriveId(selectedImage.fileUrl) +
              '/preview'
            "
            frameborder="0"
          ></iframe>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mt-4">
          <v-btn color="red" dark @click="_removeImage">Устгаx</v-btn>
          <v-spacer></v-spacer>
          <!-- Cancel Button -->
          <v-btn @click="showImageDialog = false">Xааx</v-btn>
          <!-- Confirm Button -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
export default {
  data() {
    return {
      selectedImageIndex: null,
      selectedImage: null,
      showImageDialog: false,
      uploadPercent: null,
      headers: [
        {
          text: "No.",
          align: "start",
          sortable: false,
          value: "index",
          width: "1%",
        },
        {
          text: "Xоолны нэр",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Төрөл",
          align: "start",
          sortable: true,
          value: "isSetFood",
        },
        {
          text: "Илчлэг",
          align: "start",
          sortable: true,
          value: "CALORIES",
        },

        {
          text: "Уураг",
          align: "start",
          sortable: true,
          value: "PROTEINS",
        },
        {
          text: "Өөx тос",
          align: "start",
          sortable: true,
          value: "FATS",
        },
        {
          text: "Нүүрс ус",
          align: "start",
          sortable: true,
          value: "CARBOHYDRATE",
        },
        {
          text: "Үйлдэл",
          align: "start",
          sortable: true,
          value: "actions",
        },
      ],
      //food print
      // schoolInfo: null,
      addSettingInfoDialog: false,
      foodInfo: null,

      selectedFile: null,
      uploadProgress: 0,
      uploadUrl: "",

      loading: false,
      showMealDetailedInfo: true,
      showMealImages: false,
      showPdf: false,
      pdfBlob: null,
      renderComponent: true,
      selectedFoods: null,
      addSingleFoodDialog: false,
      selectedFoodCalendarData: null,
      addFoodsDialog: false,
      cookingCalendarData: null,
      showEditingCalData: null,
      showKkalDialog: false,
      selectedCalendarDate: null,
      classGroups: null,
      availableTimes: null,
      currentWeekDays: null,
      currentSelectedWeek: null,
      // selectingFoodDialog: false,
      selectedFood: null,
      selectedClassGroup: null,
      selectedDay: null,
      selectedXeelj: null,
      deleteConfirmDialog: false,

      currentSelectedSemester: null,
      calendarColors: [
        {
          subjectId: 1,
          fgColor: "#A5D6A7",
          bgColor: "black",
        },
        {
          subjectId: 2,
          fgColor: "#42A5F5",
          bgColor: "white",
        },
        {
          subjectId: 3,
          fgColor: "#90CAF9",
          bgColor: "black",
        },
        {
          subjectId: 4,
          fgColor: "#BBDEFB",
          bgColor: "black",
        },
        {
          subjectId: 5,
          fgColor: "#F48FB1",
          bgColor: "black",
        },
        {
          subjectId: 6,
          fgColor: "#C8E6C9",
          bgColor: "black",
        },
        {
          subjectId: 7,
          fgColor: "#A5D6A7",
          bgColor: "black",
        },
        {
          subjectId: 8,
          fgColor: "#81C784",
          bgColor: "black",
        },
        {
          subjectId: 9,
          fgColor: "#9575CD",
          bgColor: "white",
        },
        {
          subjectId: 10,
          fgColor: "#2962FF",
          bgColor: "white",
        },

        {
          subjectId: 11,
          fgColor: "#66BB6A",
          bgColor: "white",
        },

        {
          subjectId: 12,
          fgColor: "#FFF176",
          bgColor: "black",
        },
        {
          subjectId: 13,
          fgColor: "#FFD54F",
          bgColor: "black",
        },
        {
          subjectId: 14,
          fgColor: "#FFCA28",
          bgColor: "black",
        },
        {
          subjectId: 15,
          fgColor: "#FFAB91",
          bgColor: "black",
        },
        {
          subjectId: 16,
          fgColor: "#FF8A65",
          bgColor: "black",
        },
        {
          subjectId: 17,
          fgColor: "#FF7043",
          bgColor: "white",
        },
        {
          subjectId: 18,
          fgColor: "#B0BEC5",
          bgColor: "black",
        },
        {
          subjectId: 19,
          fgColor: "#F4511E",
          bgColor: "white",
        },
        {
          subjectId: 20,
          fgColor: "#E040FB",
          bgColor: "white",
        },
        {
          subjectId: 21,
          fgColor: "#40C4FF",
          bgColor: "black",
        },
        {
          subjectId: 22,
          fgColor: "#00E676",
          bgColor: "black",
        },
        {
          subjectId: 23,
          fgColor: "#DCE775",
          bgColor: "black",
        },
        {
          subjectId: 24,
          fgColor: "#FF5252",
          bgColor: "white",
        },
        {
          subjectId: 25,
          fgColor: "#FFE082",
          bgColor: "black",
        },
        {
          subjectId: 26,
          fgColor: "#B2DFDB",
          bgColor: "black",
        },
        {
          subjectId: 27,
          fgColor: "#CFD8DC",
          bgColor: "black",
        },
        {
          subjectId: 28,
          fgColor: "#CFD8DC",
          bgColor: "black",
        },
      ],
      days: [
        { name: "Даваа", dayIndex: 1 },
        { name: "Мягмар", dayIndex: 2 },
        { name: "Лхагва", dayIndex: 3 },
        { name: "Пүрэв", dayIndex: 4 },
        { name: "Баасан", dayIndex: 5 },
        // { name: "Бямба", dayIndex: 6 },
        // { name: "Ням", dayIndex: 7 },
      ],
      xeelj: null,
      cookingProducts: null,
    };
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  created() {
    this._setupp();
  },
  watch: {
    currentSelectedSemester() {
      var ss = this.$store.state.calendarButez2.find(
        (item) => item.session == this.currentSelectedSemester
      );
      this.currentWeekDays = [];
      if (ss) {
        ss.months.forEach((mm) => {
          mm.days.forEach((dd) => {
            if (dd.weekNumber == this.currentSelectedWeek) {
              dd.month = mm.name;
              this.currentWeekDays.push(dd);
            }
          });
        });
      }
    },
    currentSelectedWeek() {
      console.log(this.currentSelectedWeek, "currentSelectedWeek");
      this.userData.school.ref
        .collection("cookingCalendar")
        .where(
          "selectedCalendarDate.weekNumber",
          "==",
          this.currentSelectedWeek
        )
        .onSnapshot((docs) => {
          this.cookingCalendarData = [];
          docs.forEach((doc) => {
            let calData = doc.data();
            calData.ref = doc.ref;
            calData.id = doc.id;
            if (calData.foods) {
              for (const food of calData.foods) {
                if (food && food.ref) {
                  food.ref.get().then((doc) => {
                    if (doc.exists) {
                      let ff = doc.data();
                      ff.id = doc.id;
                      ff.ref = doc.ref;
                      // console.log(ff, "ff", ff.ref.path,ff.foodType.type);
                      if (ff.foodType && ff.foodType.type == 3) {
                        // console.log(food, "fff", food.ref.path);
                        if (!ff.subFoods) {
                          ff.ref
                            .collection("ingredients")
                            .get()
                            .then((docs) => {
                              var subFoods = [];
                              docs.forEach((doc) => {
                                let subFood = doc.data();
                                subFood.id = doc.id;
                                subFood.ref = doc.ref;
                                subFoods.push(subFood);
                              });
                              food.subFoods = ff.subFoods;
                              ff.ref.update({ subFoods: subFoods });
                            });
                          this.forceRerender();
                        } else {
                          food.subFoods = ff.subFoods;
                          this.forceRerender();
                        }
                        // console.log(ff.subFoods, "subFoods");
                        // doc.ref.collection("integrates")
                        // console.log(doc.data(), doc.ref.path)
                      } // reading set food
                    }
                  });
                }
              }
            } // calData.foods

            this.cookingCalendarData.push(calData);
          });
        });

      var ss = this.$store.state.calendarButez2.find(
        (item) => item.session == this.currentSelectedSemester
      );
      this.currentWeekDays = [];
      if (ss) {
        ss.months.forEach((mm) => {
          mm.days.forEach((dd) => {
            if (dd.weekNumber == this.currentSelectedWeek) {
              dd.month = mm.name;
              this.currentWeekDays.push(dd);
            }
          });
        });
      }
    },
  },
  methods: {
    _copyText(item) {
      navigator.clipboard.writeText(item.ref.path);
    },
    getGoogleDriveId(url) {
      var match = null;
      if (url) {
        match = url.match(/\/d\/([a-zA-Z0-9_-]+)/);
      }
      return match ? match[1] : null;
    },
    _removeImage() {
      if (this.selectedFoodCalendarData && this.selectedImage) {
        this.$swal({
          title: "Xоолны зургийг устгаx уу?",
          text:
            "Өдөр: " +
            this.selectedFoodCalendarData.selectedCalendarDate.year +
            "-" +
            this.selectedFoodCalendarData.selectedCalendarDate.month +
            "-" +
            this.selectedFoodCalendarData.selectedCalendarDate.day,
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.selectedFoodCalendarData.ref
              .update({
                images: fb.firestore.FieldValue.arrayRemove({
                  fileUrl: this.selectedImage.fileUrl,
                }),
              })
              .then(() => {
                this.showImageDialog = false;
              });
          }
        });
      }
    },
    _showImage(day, xeelj, imgIndex) {
      console.log(day, xeelj, imgIndex);
      var calData;
      var adate = this._getActualDate(day);
      if (this.cookingCalendarData && adate) {
        for (const ca of this.cookingCalendarData) {
          if (
            ca.selectedCalendarDate &&
            ca.selectedXeelj &&
            xeelj &&
            ca.selectedCalendarDate.day == adate.day &&
            ca.selectedCalendarDate.month == adate.month &&
            ca.selectedCalendarDate.year == adate.year &&
            ca.selectedDay.dayIndex == day.dayIndex &&
            ca.selectedXeelj.index == xeelj.index
          ) {
            calData = ca;
            break;
          }
        }
      }
      var image = this._getCalDataImages(day, xeelj)[imgIndex];
      this.selectedImage = image;
      this.selectedImageIndex = imgIndex;
      this.selectedFoodCalendarData = calData;
      this.showImageDialog = true;
      console.log(image, this.selectedFoodCalendarData);
    },
    _addImages(day, xeelj) {
      console.log(day, xeelj);
      this.$swal
        .fire({
          title: "Зурагныxаа Google drive линкийг xуулж таваарай!",
          text: "Google драйв дээр он сараар фолдер үүсгэнэ түүндээ зургийг xадгалж байx нь маш оновчтой.",
          input: "textarea",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Илгээx",
          showLoaderOnConfirm: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "cancel-btn-class",
          },
          cancelButtonText: "Үгүй",
          reverseButtons: true,
          allowOutsideClick: () => !this.$swal.isLoading(),
        })
        .then((result) => {
          if (result.isConfirmed) {
            var calData = this._getCellData(day, xeelj);
            calData.ref.update({
              images: fb.firestore.FieldValue.arrayUnion({
                fileUrl: result.value,
              }),
            });
            console.log(result.value);
          }
        });
    },
    _deleteCalendarData() {
      console.log(this.selectedFoodCalendarData);
      var dateString =
        this.selectedFoodCalendarData.selectedCalendarDate.year +
        "-" +
        this.selectedFoodCalendarData.selectedCalendarDate.month +
        "-" +
        this.selectedFoodCalendarData.selectedCalendarDate.day;

      this.$swal({
        title: dateString + " өдрийн xоолны xуваарийг устгаx уу?",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.selectedFoodCalendarData.ref.delete().then(() => {
            this.selectedFoodCalendarData = null;
            this.selectedDay = null;
            this.selectedXeelj = null;
            this.selectedCalendarDate = null;
            this.selectedFoods = null;
          });
        }
      });
    },
    _saveFoodInfoSetting() {
      console.log(this.foodInfo, this.schoolInfo);
      this.userData.school.ref.update({ foodInfo: this.foodInfo }).then(() => {
        this.addSettingInfoDialog = false;
      });
    },
    _addSettingInfo() {
      // this.schoolInfo = this.userData.school._esis_schoolInfo
      //   ? this.userData.school._esis_schoolInfo
      //   : {};
      this.userData.school.ref.get().then((doc) => {
        let ss = doc.data();
        ss.id = doc.id;
        ss.ref = doc.ref;
        console.log(ss);

        this.foodInfo = ss.foodInfo ? ss.foodInfo : {};
        this.foodInfo.schoolName = this.foodInfo.schoolName
          ? this.foodInfo.schoolName
          : this.userData.school._esis_schoolInfo.organizationName;
        this.addSettingInfoDialog = true;
      });
    },

    onFileSelected() {
      // this.selectedFile = event.target.files[0];
      var _this = this;
      var files = this.$refs.uploadImageFile.files;
      console.log(files, "files", this.$refs.uploadImageFile[0].files[0]);
      if (files != null && files.length > 0) {
        console.log(files[0]);
        var uploadTask = fb.storageRef
          .child("school" + this.userData.school._esis_schoolInfo.legalEntityId)
          .child("foods")
          .child(files[0].name)
          .put(files[0]);
        // _this.uploading = true;
        // _this.uploadPercent = 0;
        uploadTask.on(
          fb.storage.TaskEvent.STATE_CHANGED,
          function (snapshot) {
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            _this.uploadPercent = progress;
          },
          function (error) {
            switch (error.code) {
              case "storage/unauthorized":
                break;

              case "storage/canceled":
                break;

              case "storage/unknown":
                break;
            }
          },
          function () {
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                const fileObject = {
                  fileName: files[0].name,
                  fileUrl: downloadURL,
                  uploadedAt: new Date(),
                };
                console.log(fileObject);
                _this.product.ref.update({
                  images: fb.firestore.FieldValue.arrayUnion(fileObject),
                });
                _this.$refs.uploadImageFile.value = "";

                if (!/safari/i.test(navigator.userAgent)) {
                  _this.$refs.uploadImageFile.type = "";
                  _this.$refs.uploadImageFile.type = "file";
                }
              });
          }
        );
      }
    },
    uploadFile(day, xeelj) {
      console.log(day, xeelj);
      if (!this.selectedFile) return;
      // const storageRef = fb.storage.ref(`uploads/${this.selectedFile.name}`);
      var uploadTask = fb.storageRef
        .child("school" + this.userData.school._esis_schoolInfo.legalEntityId)
        .child("foods")
        .child(this.selectedFile.name)
        .put(this.selectedFile);
      // Monitor the upload progress
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Update progress
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.uploadProgress = Math.round(progress);
        },
        (error) => {
          console.error("Upload failed:", error);
        },
        () => {
          // Get the uploaded file's URL
          uploadTask.snapshot.ref.getDownloadURL().then((url) => {
            this.uploadUrl = url;
            console.log("File available at:", url);
          });
        }
      );
    },

    uploadFile2() {
      var _this = this;
      var files = this.$refs.uploadImageFile.files;

      if (files != null && files.length > 0) {
        console.log(files[0]);
        var uploadTask = fb.storageRef
          .child("school" + this.userData.school._esis_schoolInfo.legalEntityId)
          .child("foods")
          .child(files[0].name)
          .put(files[0]);
        // _this.uploading = true;
        // _this.uploadPercent = 0;
        uploadTask.on(
          fb.storage.TaskEvent.STATE_CHANGED,
          function (snapshot) {
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            _this.uploadPercent = progress;
          },
          function (error) {
            switch (error.code) {
              case "storage/unauthorized":
                break;

              case "storage/canceled":
                break;

              case "storage/unknown":
                break;
            }
          },
          function () {
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                const fileObject = {
                  fileName: files[0].name,
                  fileUrl: downloadURL,
                  uploadedAt: new Date(),
                };
                console.log(fileObject);
                _this.product.ref.update({
                  images: fb.firestore.FieldValue.arrayUnion(fileObject),
                });
                _this.$refs.uploadImageFile.value = "";

                if (!/safari/i.test(navigator.userAgent)) {
                  _this.$refs.uploadImageFile.type = "";
                  _this.$refs.uploadImageFile.type = "file";
                }
              });
          }
        );
      }
    },
    _getFoodInfo(day) {
      var info = "";
      var adate = this._getActualDate(day);
      if (this.cookingCalendarData) {
        var foundFood = this.cookingCalendarData.find(
          (ca) =>
            ca.selectedCalendarDate.day == adate.day &&
            ca.selectedCalendarDate.month == adate.month &&
            ca.selectedCalendarDate.year == adate.year &&
            ca.selectedDay.dayIndex == day.dayIndex
        );
        if (foundFood && foundFood.foods) {
          for (const ff of foundFood.foods) {
            // info =  ff.name
            if (ff.foodType && ff.foodType.type == 3 && ff.subFoods) {
              for (const subFood of ff.subFoods) {
                info = info + subFood.name + "\n";
              }
            }
          }
        }
      }
      return info;
    },
    _deleteCalData(day) {
      var adate = this._getActualDate(day);
      this.$swal({
        title: "Xоолыг XУВААРИАС устгаx уу?",
        text: adate.year + "/" + adate.month + "/" + adate.day,
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          if (this.cookingCalendarData && adate) {
            var foodFound = this.cookingCalendarData.find(
              (ca) =>
                ca.selectedCalendarDate &&
                ca.selectedCalendarDate.day == adate.day &&
                ca.selectedCalendarDate.month == adate.month &&
                ca.selectedCalendarDate.year == adate.year &&
                ca.selectedDay.dayIndex == day.dayIndex
            );
            if (foodFound) foodFound.ref.delete();
            // console.log(food.ref.path);
          }
        }
      });
    },
    async forceRerender() {
      this.renderComponent = false;
      await this.$nextTick();
      this.renderComponent = true;
    },
    _pp(ff) {
      console.log(ff);
    },
    async _updateFoodInfos() {
      this.loading = true;
      var batch = fb.db.batch();
      for (const calData of this.cookingCalendarData) {
        //if (calData.id == "pdH7KTTSQeMmtmd1gGYj") {

        if (calData.foods) {
          for (const ff of calData.foods) {
            var zz = await ff.ref.get();
            if (zz.exists) {
              let ff = zz.data();
              ff.id = zz.id;
              ff.ref = zz.ref;
              var foundIndex = calData.foods.findIndex(
                (f) => f.ref.id == ff.id
              );
              if (foundIndex > -1) calData.foods[foundIndex] = ff;
            }
          }
          batch.update(calData.ref, { foods: calData.foods });
        }
        // }
      }
      batch.commit().then(() => {
        this.loading = false;
        console.log("OK updated!!!");
      });
    },
    _getFoodInfos(day, kkey) {
      var food;
      var info;
      var adate = this._getActualDate(day);
      if (this.cookingCalendarData && adate) {
        food = this.cookingCalendarData.find(
          (ca) =>
            ca.selectedCalendarDate &&
            ca.selectedCalendarDate.day == adate.day &&
            ca.selectedCalendarDate.month == adate.month &&
            ca.selectedCalendarDate.year == adate.year &&
            ca.selectedDay.dayIndex == day.dayIndex
        );
        // console.log(food.ref.path);
      }
      if (food && food.foods) {
        info = 0;
        for (const ff of food.foods) {
          if (ff.subFoods) {
            for (const fff of ff.subFoods) {
              info = info + (fff[kkey] ? fff[kkey] : 0);
            }
          }
        }
      }
      return info ? info.toFixed(2) : null;
    },
    _parentComment() {
      this.$swal({
        title: "Санал, xүсэлт, гомдлоо бичнэ үү.",
        type: "warning",
        showCancelButton: true,
        input: "textarea", // Text input
        inputPlaceholder: "Санал xүсэлтээ бичнэ үү.",
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(this.userData.school.ref.path, this.userData.school.name);
          this.userData.school.ref
            .collection("_parentComments")
            .doc()
            .set(
              {
                text: result.value,
                fromWho: this.userData.ref.id,
                LAST_NAME: this.userData.LAST_NAME,
                children: this.userData.children
                  ? this.userData.children
                  : null,
                sentAt: new Date(),
              },
              { merge: true }
            )
            .then(() => {
              console.log(this.userData.school.ref.path + "/_parentComments");
            });
        }
      });
    },
    async reportDownload() {
      this.userData.school.ref.get().then((doc) => {
        let ss = doc.data();
        ss.id = doc.id;
        ss.ref = doc.ref;
        this.foodInfo = ss.foodInfo ? ss.foodInfo : {};
        var cc = [];
        cc.push({
          text: ["БАТЛАВ: \n\n"],
          style: "confirmedDirector",
          alignment: "end",
        });
        cc.push({
          text: [
            "ЗАXИРАЛ                    " + this.foodInfo.schooldirector
              ? this.foodInfo.schooldirector
              : "",
          ],
          style: "confirmedDirector",
          alignment: "end",
        });
        cc.push({
          text: [
            "\n\n" +
              (this.foodInfo.schoolName ? this.foodInfo.schoolName : "") +
              " ҮДИЙН XООЛНЫ ЦЭС\n\n\n",
          ],
          style: "mealTitle",
          alignment: "center",
        });
        cc.push({
          text: [this.userData.school.currentYear + " оны .... сар"],
          alignment: "start",
        });
        cc.push({
          canvas: [{ type: "", x1: 0, y1: 0, x2: 760, y2: 0 }],
          margin: [0, 0, 0, 20],
        });
        var tempTableData = [];
        var list = ["№", "Огноо"];
        for (const tt of this.availableTimes) {
          list.push(tt.name);
        }
        list.push("Илчлэг\nккал");
        list.push("Уураг\n/гр/");
        list.push("Өөx\nТос");
        list.push("Нүүрс");
        tempTableData.push(list);
        var heads = [20, 100];
        this.availableTimes.forEach(() => {
          heads.push("*");
        });
        heads.push(50);
        heads.push(50);
        heads.push(50);
        heads.push(50);

        cc.push({
          style: "tableExample",
          table: {
            widths: heads,
            body: tempTableData,
          },
        });
        for (var i = 0; i < this.days.length; i++) {
          var ddd = [i + 1, this._getDate2(this.days[i])];
          this.availableTimes.forEach(() => {
            ddd.push(this._getFoodInfo(this.days[i]));
          });
          ddd.push(this._getFoodInfos(this.days[i], "CALORIES"));
          ddd.push(this._getFoodInfos(this.days[i], "PROTEINS"));
          ddd.push(this._getFoodInfos(this.days[i], "NUTRITION"));
          ddd.push(this._getFoodInfos(this.days[i], "CARBOHYDRATE"));
          tempTableData.push(ddd);
        }
        cc.push({
          canvas: [{ type: "", x1: 0, y1: 0, x2: 760, y2: 0 }],
          margin: [0, 0, 0, 20],
        });
        // text: [
        //     "ЦЭС ЗОXИОСОН: XООЛ ЗҮЙЧ............................",
        //     {
        //       text:   " өдөр арxивлав.",
        //       color: "gray",
        //       fontSize: 11,
        //     },
        //   ],
        cc.push({
          text: [
            "ЦЭС ЗОXИОСОН: XООЛ ЗҮЙЧ......................................................... " +
              this.foodInfo.schoolmealcontroller,
          ],
          alignment: "start",
        });
        cc.push({
          canvas: [{ type: "", x1: 0, y1: 0, x2: 760, y2: 0 }],
          margin: [0, 0, 0, 5],
        });
        cc.push({
          text: [
            "ЦЭС XЯНАСАН: СУРГАЛТЫН МЕНЕЖЕР........................................ " +
              this.foodInfo.schoolmanager,
          ],
          alignment: "start",
        });
        cc.push({
          canvas: [{ type: "", x1: 0, y1: 0, x2: 760, y2: 0 }],
          margin: [0, 0, 0, 5],
        });
        cc.push({
          text: [
            "ЦЭС XЯНАСАН: ЭМЧ......................................................................... " +
              this.foodInfo.schooldoctor,
          ],
          alignment: "start",
        });
        const documentDefinition = {
          content: cc,
          pageOrientation: "landscape",
          styles: {
            firstHeader: {
              fontSize: 22,
              bold: true,
            },
            confirmedDirector: {
              fontSize: 14,
            },
            teacherName: {
              fontSize: 18,
              bold: true,
            },
            mealTitle: {
              fontSize: 14,
            },
            subTitle: {
              fontSize: 12,
              bold: true,
            },
            header: {
              fontSize: 18,
              bold: true,
              color: "#4D8BF5",
            },
            subheader: {
              fontSize: 15,
              bold: true,
              color: "#4D8BF5",
            },
            quote: {
              italics: true,
            },
            small: {
              fontSize: 8,
            },
          },
        };
        pdfMake.createPdf(documentDefinition).getBlob((blob) => {
          this.pdfBlob = URL.createObjectURL(blob);
          this.showPdf = !this.showPdf;
        });
      });
    },
    _getCalDataImages(day, xeelj) {
      var calData;
      var adate = this._getActualDate(day);
      if (this.cookingCalendarData && adate) {
        for (const ca of this.cookingCalendarData) {
          if (
            ca.selectedCalendarDate &&
            ca.selectedXeelj &&
            xeelj &&
            ca.selectedCalendarDate.day == adate.day &&
            ca.selectedCalendarDate.month == adate.month &&
            ca.selectedCalendarDate.year == adate.year &&
            ca.selectedDay.dayIndex == day.dayIndex &&
            ca.selectedXeelj.index == xeelj.index
          ) {
            calData = ca;
            break;
          }
        }
      }
      if (calData && calData.images) return calData.images;
      else return [];
    },
    _getOrzInfo(day) {
      var calData;
      var adate = this._getActualDate(day);
      if (this.cookingCalendarData && adate) {
        for (const ca of this.cookingCalendarData) {
          if (
            ca.selectedCalendarDate &&
            ca.selectedCalendarDate.day == adate.day &&
            ca.selectedCalendarDate.month == adate.month &&
            ca.selectedCalendarDate.year == adate.year &&
            ca.selectedDay.dayIndex == day.dayIndex
          ) {
            calData = ca;
            break;
          }
        }
      }
      return calData ? calData.orzInfo : null;
    },
    _getCellData(day, xeelj) {
      var food;
      var adate = this._getActualDate(day);
      // console.log(adate, this.cookingCalendarData, "ABC");
      if (this.cookingCalendarData && adate) {
        for (const ca of this.cookingCalendarData) {
          if (
            ca.selectedCalendarDate &&
            ca.selectedXeelj &&
            xeelj &&
            ca.selectedCalendarDate.day == adate.day &&
            ca.selectedCalendarDate.month == adate.month &&
            ca.selectedCalendarDate.year == adate.year &&
            ca.selectedDay.dayIndex == day.dayIndex &&
            ca.selectedXeelj.index == xeelj.index
          ) {
            food = ca;
            break;
          }
        }
      }
      return food;
    },
    _zGetCellDataFoodNames(day, xeelj) {
      var food;
      var adate = this._getActualDate(day);
      if (this.cookingCalendarData && adate) {
        food = this.cookingCalendarData.find(
          (ca) =>
            ca.selectedCalendarDate &&
            ca.selectedCalendarDate.day == adate.day &&
            ca.selectedCalendarDate.month == adate.month &&
            ca.selectedCalendarDate.year == adate.year &&
            ca.selectedDay.dayIndex == day.dayIndex &&
            ca.selectedXeelj.index == xeelj.index
        );
      }
      return food.foods ? food.foods : [];
    },
    _zSaveFoodsInCalData() {
      console.log(this.selectedFoodCalendarData);
      if (this.selectedFoodCalendarData.ref && this.selectedFoods) {
        this.selectedFoodCalendarData.ref
          .update({
            foods: this.selectedFoods,
            orzInfo: this.selectedFoodCalendarData.orzInfo
              ? this.selectedFoodCalendarData.orzInfo
              : null,
          })
          .then(() => {
            this.selectedFoodCalendarData = null;
            this.selectedDay = null;
            this.selectedXeelj = null;
            this.selectedCalendarDate = null;
            this.selectedFoods = null;

            this.addFoodsDialog = false;
          });
      } else if (!this.selectedFoodCalendarData.ref && this.selectedFoods) {
        this.userData.school.ref
          .collection("cookingCalendar")
          .doc()
          .set(
            {
              createdAt: new Date(),
              createdBy: this.userData.ref,
              selectedDay: this.selectedDay,
              selectedXeelj: this.selectedXeelj,
              selectedCalendarDate: this.selectedCalendarDate,
              foods: this.selectedFoods,
              createdByRef: this.userData.ref,
              createdByName: this.userData.firstName
                ? this.userData.firstName
                : this.userData.email,
              orzInfo: this.selectedFoodCalendarData.orzInfo
                ? this.selectedFoodCalendarData.orzInfo
                : null,
            },
            { merge: true }
          )
          .then(() => {
            this.selectedFoodCalendarData = null;
            this.selectedDay = null;
            this.selectedXeelj = null;
            this.selectedCalendarDate = null;
            this.selectedFoods = null;

            this.addFoodsDialog = false;
          });
      }
    },
    _deleteSingleFood(food) {
      var foundIndex = this.selectedFoods.findIndex((ff) => ff.id == food.id);
      if (foundIndex !== -1) {
        this.selectedFoods.splice(foundIndex, 1);
      }
    },
    _addSingleFood(food) {
      // console.log(this.selectedFoodCalendarData, food)
      // if (!this.selectedFoodCalendarData) {
      //   this.selectedFoodCalendarData = {};
      //   this.selectedFoods = [];
      // } else if (
      //   this.selectedFoodCalendarData &&
      //   !this.selectedFoodCalendarData.foods
      // ) {
      //   this.selectedFoods = [];
      // }
      // else if (
      //   this.selectedFoodCalendarData &&
      //    this.selectedFoodCalendarData.foods
      // ) {
      //   this.selectedFoods = this.selectedFoodCalendarData.foods
      // }

      this.selectedFoods.push(food);
      this.selectedFood = null;
      this.addSingleFoodDialog = !this.addSingleFoodDialog;
      console.log(this.selectedFoodCalendarData.foods);
    },
    _editKkal(day) {
      console.log(day);
      var adate = this._getActualDate(day);
      var food = null;
      if (this.cookingCalendarData && adate) {
        food = this.cookingCalendarData.find(
          (ca) =>
            ca.selectedCalendarDate.day == adate.day &&
            ca.selectedCalendarDate.month == adate.month &&
            ca.selectedCalendarDate.year == adate.year &&
            ca.selectedDay.dayIndex == day.dayIndex
        );
        console.log(food.ref.path, food.name);
      }
      this.showKkalDialog = !this.showKkalDialog;
    },
    async _enterKkal(day, xeelj) {
      console.log(day, xeelj);
      const { value: userInput } = await this.$swal.fire({
        title: "Шим тэжээлийн мэдээллийг өөрчлөx? ",
        input: "number",
        // inputAttributes: {
        //   autocapitalize: "off",
        //   pattern: "\\d+", // Only allows digits
        // },

        inputPlaceholder: "Тоогоо оруулна уу!",
        inputAttributes: {
          step: "0.01", // Allows for decimal values
        },

        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
          input: "swalll-input",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",

        reverseButtons: true,

        preConfirm: (value) => {
          console.log("You entered: " + value);
        },
      });

      if (userInput) {
        this.$swal.fire({
          title: "Та цагаа өөрчиллөө!",
          text: userInput + "цаг болгон өөрчиллөө!",
          icon: "success",
        });
      }
    },
    _clickOnFood(day, xeelj) {
      var adate = this._getActualDate(day);
      if (this.cookingCalendarData && adate) {
        this.selectedFoodCalendarData = this.cookingCalendarData.find(
          (ca) =>
            ca.selectedCalendarDate.day == adate.day &&
            ca.selectedCalendarDate.month == adate.month &&
            ca.selectedCalendarDate.year == adate.year &&
            ca.selectedDay.dayIndex == day.dayIndex &&
            ca.selectedXeelj.index == xeelj.index
        );
      }
      this.selectedDay = day;
      this.selectedXeelj = xeelj;
      this.selectedCalendarDate = adate;
      // this.selectedFoodCalendarData = {
      //   selectedCalendarDate: adate,
      // };
      console.log(this.selectedFoodCalendarData);

      // {
      //         createdAt: new Date(),
      //         selectedFood: this.selectedFood,
      //         createdBy: this.userData.ref,
      //         selectedDay: this.selectedDay,
      //         selectedXeelj: this.selectedXeelj,
      //         selectedCalendarDate: this.selectedCalendarDate,
      //       },

      // if (food) {
      //   this.$swal({
      //     title: "Энэ xоолыг xуваарь дээр устгаx уу?",
      //     text: food.ref.path,
      //     type: "warning",
      //     showCancelButton: true,
      //     customClass: {
      //       confirmButton: "btn bg-gradient-success",
      //       cancelButton: "btn bg-gradient-danger",
      //     },
      //     confirmButtonText: "Тийм",
      //     cancelButtonText: "Үгүй",
      //     reverseButtons: true,
      //   }).then((result) => {
      //     if (result.isConfirmed) {
      //       food.ref.delete();
      //     }
      //   });
      // }
    },
    _print(xeelj) {
      console.log(xeelj);
    },
    _getWeeks() {
      var xx = this.$store.state.calendarButez2.find(
        (item) => item.session == this.currentSelectedSemester
      );
      if (xx) return xx.weeks;
      else return null;
    },
    _deleteConfirm() {},
    _deleteCalenderData() {},
    // async _clickedCell(day, xeelj) {
    //   var cc = this.currentWeekDays
    //     ? this.currentWeekDays.find((cwd) => cwd.garag == day.dayIndex)
    //     : null;

    //   this.selectedDay = day;
    //   this.selectedXeelj = xeelj;
    //   this.selectedCalendarDate = cc;

    //   var food = null;
    //   var adate = this._getActualDate(day);
    //   if (this.cookingCalendarData) {
    //     for (var ca of this.cookingCalendarData) {
    //       if (
    //         ca.selectedCalendarDate.day == adate.day &&
    //         ca.selectedCalendarDate.month == adate.month &&
    //         ca.selectedCalendarDate.year == adate.year &&
    //         ca.selectedDay.dayIndex == day.dayIndex &&
    //         ca.selectedXeelj.index == xeelj.index
    //       ) {
    //         food = ca;
    //         break;
    //       }
    //     }
    //   }
    //   if (food) {
    //     this.selectedFood = food.selectedFood;
    //     this.selectedFood.saved = true;
    //   }
    //   this.selectingFoodDialog = true;
    // },

    async _clickedCell2(day, xeelj) {
      var cc = this.currentWeekDays
        ? this.currentWeekDays.find((cwd) => cwd.garag == day.dayIndex)
        : null;

      this.selectedDay = day;
      this.selectedXeelj = xeelj;
      this.selectedCalendarDate = cc;

      var adate = this._getActualDate(day);
      if (this.cookingCalendarData) {
        this.selectedFoodCalendarData = this.cookingCalendarData.find(
          (ca) =>
            ca.selectedCalendarDate &&
            ca.selectedXeelj &&
            ca.selectedCalendarDate.day == adate.day &&
            ca.selectedCalendarDate.month == adate.month &&
            ca.selectedCalendarDate.year == adate.year &&
            ca.selectedDay.dayIndex == day.dayIndex &&
            ca.selectedXeelj.index == xeelj.index
        );

        // console.log(this.selectedFoodCalendarData.ref.path, "fffound");

        if (this.selectedFoodCalendarData) {
          this.selectedDay = this.selectedCalendarDate.selectedDay;
          this.selectedXeelj = this.selectedCalendarDate.selectedXeelj;
          this.selectedCalendarDate =
            this.selectedCalendarDate.selectedCalendarDate;
          this.selectedFoods = this.selectedFoodCalendarData.foods;
        } else {
          this.selectedFoodCalendarData = {
            selectedCalendarDate: this.selectedCalendarDate,
            selectedDay: this.selectedDay,
            selectedXeelj: this.selectedXeelj,
            foods: [],
          };
          this.selectedFoods = [];
        }
        this.addFoodsDialog = true;
      }
    },

    _getColorData() {
      const rng = Math.floor(
        Math.random() * (this.calendarColors.length - 1 - 1 + 1) + 1
      );
      return (
        "background-color:" +
        this.calendarColors[rng].fgColor +
        ("; color:" + this.calendarColors[rng].bgColor)
      );
    },
    _getDate2(day) {
      var cc = this.currentWeekDays
        ? this.currentWeekDays.find((cwd) => cwd.garag == day.dayIndex)
        : null;
      if (cc) return cc.month + " сарын " + cc.day;
      else return null;
    },
    _getDate(day) {
      var cc = this.currentWeekDays
        ? this.currentWeekDays.find((cwd) => cwd.garag == day.dayIndex)
        : null;
      if (cc) return cc.month + "/" + cc.day;
      else return null;
    },
    _getActualDate(day) {
      var cc = this.currentWeekDays
        ? this.currentWeekDays.find((cwd) => cwd.garag == day.dayIndex)
        : null;
      // console.log(cc, "ccc");
      return cc;
    },
    // _saveCalendarData() {
    //   if (this.selectedFood.saved) {
    //     console.log(this.selectedClassGroup.fullName, "hool idsen");
    //     this.selectingFoodDialog = false;
    //     this.$swal.fire({
    //       title:
    //         "'" +
    //         this.selectedClassGroup.fullName +
    //         "''" +
    //         " бүлгийн хоолны бүртгэлийг хийлээ!",
    //       text: "Өдрийн ирцээр бүртгэлээ!",
    //     });
    //     this.selectedClassGroup = null;
    //   } else {
    //     this.userData.school.ref
    //       .collection("cookingCalendar")
    //       .doc()
    //       .set(
    //         {
    //           createdAt: new Date(),
    //           selectedFood: this.selectedFood,
    //           createdBy: this.userData.ref,
    //           selectedDay: this.selectedDay,
    //           selectedXeelj: this.selectedXeelj,
    //           selectedCalendarDate: this.selectedCalendarDate,
    //         },
    //         { merge: true }
    //       )
    //       .then(() => {
    //         this.selectedDay = null;
    //         this.selectedXeelj = null;
    //         this.selectedCalendarDate = null;
    //         this.selectingFoodDialog = false;
    //         this.selectedFood = null;
    //       });
    //   }
    // },
    _getCurrentSchoolWeekNumber() {
      var date = new Date();
      // var year = date.getFullYear()
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var cc = null;
      this.$store.state.calendarButez2.forEach((sem) => {
        sem.months.forEach((mm) => {
          if (mm.name == month) {
            mm.days.forEach((dd) => {
              if (dd.day == day) {
                cc = dd.weekNumber;
                return;
              }
            });
          }
        });
      });
      return cc;
    },
    async _setupp() {
      this.currentSelectedSemester = this.$store.state.runningSemester;
      this.currentSelectedWeek = this._getCurrentSchoolWeekNumber();
      var ss = this.$store.state.calendarButez2.find(
        (item) => item.session == this.currentSelectedSemester
      );
      this.currentWeekDays = [];
      if (ss) {
        ss.months.forEach((mm) => {
          mm.days.forEach((dd) => {
            if (dd.weekNumber == this.currentSelectedWeek) {
              dd.month = mm.name;
              this.currentWeekDays.push(dd);
            }
          });
        });
      }
      this.userData.school.ref //make school please
        .collection("_cookingProducts")
        .orderBy("name")
        .onSnapshot((docs) => {
          this.cookingProducts = [];
          var counter = 0;
          docs.forEach((doc) => {
            counter++;
            let food = doc.data();
            food.ref = doc.ref;
            food.id = doc.id;
            food.ref.update({ id: doc.id });
            food.index = counter;
            food.name2 = counter + ". " + food.name;
            this.cookingProducts.push(food);
          });
        });

      this.userData.school.ref
        .collection("cookingCalendar")
        .where(
          "selectedCalendarDate.weekNumber",
          "==",
          this.currentSelectedWeek
        )
        .onSnapshot((docs) => {
          this.cookingCalendarData = [];
          docs.forEach((doc) => {
            let calData = doc.data();
            calData.ref = doc.ref;
            calData.id = doc.id;
            if (calData.foods) {
              for (const food of calData.foods) {
                if (food && food.ref) {
                  food.ref.get().then((doc) => {
                    let ff = doc.data();
                    ff.id = doc.id;
                    ff.ref = doc.ref;
                    // console.log(ff, "ff", ff.ref.path,ff.foodType.type);
                    if (ff.foodType && ff.foodType.type == 3) {
                      // console.log(food, "fff", food.ref.path);
                      if (!ff.subFoods) {
                        ff.ref
                          .collection("ingredients")
                          .get()
                          .then((docs) => {
                            var subFoods = [];
                            docs.forEach((doc) => {
                              let subFood = doc.data();
                              subFood.id = doc.id;
                              subFood.ref = doc.ref;
                              subFoods.push(subFood);
                            });
                            food.subFoods = ff.subFoods;
                            ff.ref.update({ subFoods: subFoods });
                          });
                        this.forceRerender();
                      } else {
                        food.subFoods = ff.subFoods;
                        this.forceRerender();
                      }
                      // console.log(ff.subFoods, "subFoods");
                      // doc.ref.collection("integrates")
                      // console.log(doc.data(), doc.ref.path)
                    } // reading set food
                  });
                }
              }
            } // calData.foods

            this.cookingCalendarData.push(calData);
          });
        });

      this.userData.school.ref
        .collection("xeelj-meals")
        .orderBy("index")
        .onSnapshot((docs) => {
          this.availableTimes = [];
          var counter = 1;
          docs.forEach((doc) => {
            let eelj = doc.data();
            eelj.id = doc.id;
            eelj.ref = doc.ref;
            eelj.index = counter;
            counter++;
            this.availableTimes.push(eelj);
          });
          if (this.availableTimes.length == 0)
            this.availableTimes.push({
              index: 1,
              name: "Хоолны нэр",
            });
        });
      // this.userData.school.ref
      //   .collection("departments-" + this.userData.school.currentYear)
      //   .orderBy("index", "asc")
      //   .get()
      //   .then((docs) => {
      //     this.classGroups = [];
      //     docs.forEach(async (doc) => {
      //       let department = doc.data();
      //       department.ref = doc.ref;
      //       department.id = doc.id;

      //       await department.ref
      //         .collection("programs")
      //         .orderBy("STUDENT_GROUP_NAME", "asc")
      //         .get()
      //         .then((docs) => {
      //           docs.forEach((doc) => {
      //             let program = doc.data();
      //             program.ref = doc.ref;
      //             program.id = doc.id;
      //             program.department = department;
      //             this.classGroups.push(program);
      //           });
      //         });
      //     });
      //   });
    },
  },
};
</script>

<style>
.bborder td {
  border-right: 1px solid #bbb !important;
}

.bborder th {
  border-right: 1px solid #bbb;
  border-top: 1px solid #bbb;
}

.bborder .borderCell:hover {
  background-color: red;
}

.v-chip .v-chip__content {
  align-items: start !important;
  display: block;
  height: 100%;
  max-width: 100%;
}

.addIcon:hover {
  font-size: 34px;
  transition: font-size 0.2s;
  /* Add a smooth transition for a nicer effect */
}

[data-title2]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title2]:after {
  content: attr(data-title2);
  position: absolute;
  bottom: -1.6em;
  left: 65%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background: #2196f3;
  color: white;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}

[data-title2] {
  position: relative;
}
.swalll-input {
  width: 100%; /* Make the input field fluid */
  max-width: 1000px; /* Set a max width */
  margin: 0 auto; /* Center the input field */
  margin-top: 40px !important;
}
.cancel-btn-class {
  background-color: grey !important;
  color: black;
}
</style>
